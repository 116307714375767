import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, output } from '@angular/core';

import { ButtonSize, ButtonSizes, ButtonType, ButtonTypes } from './types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class ButtonComponent {
  @Input()
  type: ButtonTypes = ButtonType.Primary;

  @Input()
  active = false;

  @Input()
  disabled = false;

  @Input()
  size: ButtonSizes = ButtonSize.Big;

  @Input()
  hasSecondIcon = false;

  @Input()
  hasGhostText = false;

  onClick = output<void>();

  protected readonly ButtonType = ButtonType;

  @HostBinding('class.cc-disabled')
  get disabledClass(): boolean {
    return this.disabled;
  }
}
