<div
  [class.has-ghost-text]="hasGhostText"
  [class.has-second-icon]="hasSecondIcon"
  [class]="[type, size]"
  class="button-wrapper"
>
  <button
    (click)="onClick.emit()"
    [class.active]="active"
    [class.disabled]="disabled"
    [class.has-ghost-text]="hasGhostText"
    [class.has-second-icon]="hasSecondIcon"
    [class]="[type, size]"
    [disabled]="disabled"
    class="button"
  >
    @if (type === ButtonType.Primary || type === ButtonType.Secondary || type === ButtonType.Danger) {
      <div class="button__start-icon">
        <ng-content select="[slot='start-icon']"></ng-content>
      </div>
      <span class="button__text">
        <ng-content></ng-content>
      </span>
      <div class="button__end-icon">
        <ng-content select="[slot='end-icon']"></ng-content>
      </div>
    }

    @if (type === ButtonType.Ghost || type === ButtonType.GhostInvert || type === ButtonType.GhostText) {
      <div class="button__ghost-icon">
        @if (type === ButtonType.GhostText) {
          <ng-container [ngTemplateOutlet]="ghostText"></ng-container>
        }
        <ng-content select="[slot='ghost-icon']"></ng-content>
      </div>
      @if (type !== ButtonType.GhostText) {
        <ng-container [ngTemplateOutlet]="ghostText"></ng-container>
      }
      @if (hasSecondIcon) {
        <div class="button__second-icon">
          <ng-content select="[slot='second-icon']"></ng-content>
        </div>
      }
    }
  </button>
</div>

<ng-template #ghostText>
  <ng-content select="[slot='ghost-text']"></ng-content>
</ng-template>
